//colors
$color-primary: #080b13;
$color-secondary: #e9ecef;

$color-black: #000;
$color-white: #fff;
$color-gray: #646464;

$color-completed: #1ed760;
$color-in-process: #35b0ab;
$color-waiting: #b47269;
