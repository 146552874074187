::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;

  &-track {
    background: $color-white;
    box-shadow: inset 0 0 0.5rem rgba($color-black, 0.6);
  }

  &-thumb {
    background: $color-gray;
    border-radius: 330rem;
    &:hover {
      background: lighten($color-gray, 20%);
    }
  }
}
