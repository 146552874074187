.hamburger {
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 10;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: rgb(8, 11, 19);
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 7px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -7px);
}
