.list {
  margin-top: 1rem;
  text-align: center;
  font-size: 1.6rem;
  position: relative;

  &__col-heading {
    display: inline-block;
    width: 25%;
    padding: 0.5rem 0;
    background: $color-primary;
    color: white;
    border-right: 1px solid darkgray;

    &:first-child {
      border-radius: 10px 0 0 0;
    }

    &:nth-child(4) {
      border-radius: 0 10px 0 0 !important;
    }
  }

  ol {
    list-style-type: none;
    padding: 0;
    height: 40rem;
    overflow-y: scroll;
    margin-top: 3px;
    box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.5);
    background: white;
    border-radius: 0px;
  }

  &__card {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    width: 110rem;

    div {
      display: inline-block;
      padding: 0.5rem 1rem;
      text-align: left;
      width: 27.5rem;
      border-right: 1px solid lightgray;
    }

    &:hover {
      background: lighten($color-primary, 20%);
      color: white;
      border: none;
      cursor: pointer;
    }
  }
}