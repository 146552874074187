.login {
  background: $color-secondary;
  height: 100vh;
  position: relative;

  &__main {
    position: absolute;
    width: 35rem;
    top: calc(50% - (15rem));
    left: calc(50% - (35rem / 2));
    border-right: 1px solid darken($color-secondary, 30%);
    border-left: 1px solid darken($color-secondary, 30%);

    &__logo {
      width: 20rem;
    }

    &__heading {
      width: 100%;
      font-weight: bold;
      font-size: 1.6rem;
      letter-spacing: 1px;
    }

    &__form {
      padding: 1rem;

      input {
        background: white;
        border: 1px solid black;
        padding: 0.5rem 2rem;
        margin: 0.5rem;
        width: 80%;
        border-radius: 10rem;
        font-size: 1.6rem;
        &:focus {
          outline: none;
        }
      }

      button {
        background: $color-gray;
        border: none;
        color: $color-white;
        letter-spacing: 1px;
        padding: 0.5rem 1rem 0.7rem;
        width: 20rem;
        border-radius: 15rem;
        transition: 0.2s;
        font-size: 1.8rem;
        margin-bottom: 1rem;

        &:hover {
          background: $color-primary;
        }

        &:focus {
          outline: none;
        }
      }

      .alert {
        margin-top: 1rem;
        font-size: 1.3rem;
      }
    }
  }
}
