//text align left
.tal {
  text-align: left;
}

//text align right
.tar {
  text-align: right;
}

//margin-left-medium
.mlm {
  margin-left: 1rem;
}

//margin right medium
.mrm {
  margin-right: 1rem;
}

.mtm {
  margin-top: 1rem;
}

.screen-center {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rel {
  position: relative;
}