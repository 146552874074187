.loading {
  width: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
}

.block-loader {
  position: relative;
  box-sizing: border-box;
  float: left;
  margin: 0 10px 10px 0;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background-color: #080b13;
}

.block-loader:nth-child(4n + 1) {
  animation: wave 2s ease 0s infinite;
}
.block-loader:nth-child(4n + 2) {
  animation: wave 2s ease 0.2s infinite;
}
.block-loader:nth-child(4n + 3) {
  animation: wave 2s ease 0.4s infinite;
}
.block-loader:nth-child(4n + 4) {
  animation: wave 2s ease 0.6s infinite;
  margin-right: 0;
}

@keyframes wave {
  0% {
    top: 0;
    opacity: 1;
  }
  50% {
    top: 30px;
    opacity: 0.2;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
