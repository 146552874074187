.form {
  width: 50%;
  margin: auto;
  padding: 3rem;
  text-align: left;
  font-size: 1.4rem;

  label {
    font-weight: bold;
    font-size: 1.2rem;
    margin: 0;
    margin-top: 1rem;
  }

  input {
    border: 1px solid $color-gray;
    border-radius: 0.3rem;
    padding: 0.3rem 0.8rem;
    width: 100%;
    background: transparent;
  }

  textarea {
    width: 100%;
    height: 8rem;
    padding: 0.3rem 0.8rem;
    resize: none;
    border-radius: 3px;
    border: 1px solid $color-gray;
    background: transparent;
  }

  select {
    width: 100%;
    border: 1px solid $color-gray;
    border-radius: 3px;
    padding: 3px 8px;
    background: transparent;
  }

  .button-primary {
    width: 100%;
    margin-top: 1rem;
  }
}