.heading-primary {
  text-align: right;
  font-size: 3rem;
  font-weight: bold;
  margin-top: 5px;
  padding-right: 6px;
  padding-top: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgray;
}

.heading-secondary {
  font-size: 2.8rem;
  font-weight: bold;
  padding-bottom: 5px;
}

.heading-tertiary {
  font-size: 2.4rem;
  font-weight: bold;
  padding-bottom: 5px;
}

.link {
  font-size: 1.6rem;
  padding-top: 1rem;
}
