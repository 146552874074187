@mixin mixin-button {
  border: none;
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.2s;
}

.button-primary {
  @include mixin-button;
  background-color: $color-primary;
  color: $color-secondary;

  &:hover,
  :active {
    background-color: lighten($color-primary, 15%);
  }

  &:focus {
    outline: none;
  }

  &--outline {
    border: 1px solid $color-primary;
    background: transparent;
    color: $color-primary;

    &:hover {
      background-color: $color-primary;
      color: $color-secondary;
    }
  }
}

.button-secondary {
  @include mixin-button;
  background-color: $color-secondary;
  color: $color-primary;

  &:hover,
  :active {
    background-color: darken($color-secondary, 25%);
  }

  &:focus {
    outline: none;
  }

  &--outline {
    border: 1px solid $color-secondary;
    background: transparent;
    color: $color-secondary;

    &:hover {
      background-color: $color-secondary;
      color: $color-primary;
    }
  }
}

.loadmore {
  margin: 2rem 0;
  border: none;
  background: none;
  color: $color-gray;
  border-top: 1px solid $color-gray;
  transition: 0.2s;
  padding: 0 1rem 0.3rem;

  &:hover {
    color: $color-white;
    background-color: $color-primary;
  }

  &:focus {
    outline: none;
  }
}
