.task-card {
  font-size: 1.4rem;
  text-align: left;
  background-color: $color-white;
  margin: 1rem;
  color: darken($color-gray, 20%);
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-top: 5px solid $color-gray;

  &__container {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    position: relative;

    p {
      padding: 0.2rem 1rem;
      margin: 0;
      border-bottom: 1px solid $color-secondary;
    }
  }

  &__body {
    display: flex;
    padding: 1rem 0;
    position: relative;

    div {
      margin-right: 1rem;
      width: 25%;
      padding-left: 1rem;
      border-left: 1px solid lightgray;

      &:first-child {
        border: none;
      }
    }
  }

  strong {
    color: $color-primary;
    letter-spacing: 1px;
    border-bottom: none !important;
  }

  &--COMPLETED {
    border-top: 5px solid $color-completed;
  }

  &--IN-PROCESS {
    border-top: 5px solid $color-in-process;
  }

  &--WAITING-FOR-DOCUMENTS {
    border-top: 5px solid $color-waiting;
  }

  .hidden-info {
    height: 0;
    border-radius: 2px;
    transition: 0.3s;
    overflow-y: hidden;

    .row {
      height: 15rem;
      width: 100%;
      margin: 0;
    }

    p {
      height: 8rem;
    }

    &__left-div {
      padding: 1rem;
      border-right: 1px solid $color-secondary;
    }

    &__right-div {
      padding: 1rem;
    }

    &--show {
      height: 15rem;
    }
  }

  .toggle-down {
    background: transparent;
    border: none;
    position: absolute;
    right: 1rem;
    top: 0.3rem;
  }

  .toggle-down:focus {
    outline: none;
  }

  .button-primary {
    margin-left: 1rem;
  }

  &__select-status {
    display: none;
    bottom: 5.3rem;
    padding: 1rem;
    width: 20rem;
    background: white;
    position: absolute;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    overflow-y: hidden;

    select {
      padding: 0.5rem;
      width: 100%;
      margin-bottom: 0.3rem;
      border: 1px solid $color-primary;
    }

    button {
      width: 100%;
      margin: 0 !important;
    }

    &--show {
      display: block;
    }
  }

  &__delete-form {
    display: none;
    position: absolute;
    width: 20rem;
    right: 22rem;
    bottom: 1rem;
    background: white;
    padding: 0.5rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    border-radius: 5px;

    label {
      font-size: 0.8em;
      font-weight: bold;
      margin: 0;
      margin-bottom: 4px;
    }

    input {
      padding: 0px 5px;
      width: 100%;
      border: 1px solid black;
      border-radius: 3px;
      margin-bottom: 0.3rem;
    }

    button {
      width: 100%;
      margin: 0 !important;
    }

    &--show {
      display: block;
    }
  }
}

.remarks {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px rgba(black, .6);
  z-index: 100;
  background: white;
  height: 40rem;
  width: 90rem;
  opacity: 100%;
  display: none;
  border-radius: 1rem;

  &-show {
    display: block; opacity: 100;
  }

  &__close {
    position: absolute;
    right: 1rem;
    background-color: transparent;
    border: none;
    font-weight: bold;
  }

  &__body {
    width: 100%;
    height: 36rem;
    margin: auto;
    margin-top: 1rem;

    &__leftsection {
      background: $color-secondary;
      overflow-y: scroll;

      &__remarkslist {
        box-shadow: none !important;
        text-align: left;
        padding: 1rem !important;
        background: transparent !important;

        &__item {
          background-color: white;
          margin: 1rem 0;
          padding: .5rem;
          border-radius: .5rem;

        }
      }
    }

    &__rightsection {
      background-color: $color-primary;

      &__textarea {
        margin-top: 40px;
        border-radius: .5rem;
        resize: none;
        height: 25rem;
        width: 100%;
        padding: 1rem .5rem;

        &:focus {
          outline: none;
        }
      }

      &__submit {
        width: 100%;
        font-weight: bold;
      }
    }
  }
}