.dashboard {
  font-size: 1.6rem;

  &__stats {
    justify-content: center;
    background: $color-white;
    border: 1px solid $color-secondary;
    padding: 0.5rem 1rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    width: 80%;
    border-radius: 5px;
    margin: auto;

    div {
      display: inline-block;
      border-right: 1px solid $color-secondary;
      padding: 0 5px;
      width: 25%;
      text-align: center;

      &:last-child {
        border-right: none;
      }
    }

    p {
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      color: $color-primary;
      margin: 0;
    }
  }

  strong {
    border-bottom: 1px solid rgb(207, 194, 194);
    padding-bottom: 3px;
    letter-spacing: 0.5px;
  }

  &__buttons {
    padding: 10px;
    background: white;
    border: 1px solid rgb(179, 181, 182);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;

    button {
      width: 100%;
      margin-bottom: 4px;
    }
  }
}
