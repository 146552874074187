.sidebar {
  height: 100%;
  width: 28rem;
  position: fixed;
  visibility: visible;
  top: 0;
  left: 0;
  background-color: $color-primary;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: inset 0px 0px 2rem rgba($color-black, 0.5);

  &__profile {
    width: 28rem;
    padding: 6rem 0;
    margin-bottom: 2rem;

    h6 {
      font-size: 2.5rem;
      color: $color-secondary;
    }

    small {
      color: gold;
      font-size: 1.2rem;
      letter-spacing: 2px;
    }
  }

  &__links {
    padding-top: 2rem;

    .link {
      display: block;
      width: 28rem;
      font-size: 2rem;
      transition: 0.2s;
      padding: 5px;
      color: $color-secondary;

      &:hover {
        background-color: lighten($color-primary, 10%);
        text-decoration: none;
        color: white;
      }
    }
  }

  &__bottom {
    width: 28rem;
    position: absolute;
    bottom: 1rem;
    background-color: inherit;
  }

  &--hidden {
    width: 0;
    visibility: hidden;
  }
}
