.profile {
  font-size: 1.6rem;
  // position: relative;
  min-height: 600px;

  &__header {
    padding: 20px;
    display: flex;
    border-bottom: 1px solid lightgray;

    &__info {
      text-align: left;
      width: 80%;
      border-right: 1px solid lightgray;

      p {
        margin: 0;
      }
    }

    &__stats {
      text-align: left;
      width: 20%;
      padding: 10px;

      p {
        margin: 0;
      }
    }
  }

  .list ol {
    height: 420px;
  }
}