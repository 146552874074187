.main {
  transition: margin-left 0.5s;
  position: relative;
  padding-top: 2rem;
}

.main--slide {
  margin-left: 28rem;
}

.button-ham {
  background: none;
  border: none;
  position: absolute;
  left: 20px;
  top: 20px;

  &:focus {
    outline: none;
  }
}

.goback {
  left: 80px;
  top: 20px;

  position: absolute;
}

.reload {
  position: absolute;
  right: 3rem;
  top: .5rem;
  font-size: 3rem;
  color: $color-primary;
  background: transparent;
  border: none;


  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &-rotate {
    animation: rotate 1s linear;
    animation-iteration-count: infinite;
  }

}