.tasks-navigator {
  display: inline-block;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;

  button {
    min-width: 100px;
    border: none;
    padding: 4px 10px;
    background: $color-white;
    border: 1px solid $color-secondary;
    border-right: none;
    transition: 0.1s;
    letter-spacing: 0.5px;

    &:first-child {
      border-radius: 5px 0px 0px 5px;
    }

    &:last-child {
      border-radius: 0px 5px 5px 0px;
    }
  }

  &:focus {
    outline: none;
  }

  .active-btn {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.7);
    background: $color-primary;
    color: $color-secondary;
  }
}
